import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Menu,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import BocBang from "../Bocbang";
import { useTranslation } from "react-i18next";
import Icon, {
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { FaPen, FaPencilAlt } from "react-icons/fa";
import { updateContent } from "../services/apiService";
import { Skeleton } from "antd";

const { Text } = Typography;

const MainTextDisplay = ({
  isContentLoading,
  setCurrentSession,
  getSessionsToView,
  currentSession,
  isListening,
  listTranscript,
  setListTranscript,
  getContentToView,
}) => {
  const [editMess, setEditMess] = useState(false);
  const [form] = Form.useForm();
  const [displayedText, setDisplayedText] = useState([]);

  useEffect(() => {
    if (listTranscript) {
      setDisplayedText(listTranscript);
    }
  }, [listTranscript]);
  const { t, i18n } = useTranslation("global");
  const menuItems = (record) => [
    {
      key: "1",
      label: () => <div>Sửa</div>,
    },
    {
      key: "2",
      label: () => (
        <div
          onClick={() => {
            setListTranscript((pre) =>
              pre?.filter((item) => item?.id !== record?.id)
            );
          }}
        >
          Xóa
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: "Share",
    // },
  ];

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleMenuClick = (info) => {
    // console.log("Menu item clicked:", info.key);
    setPopoverVisible(false); // Close the popover when a menu item is clicked
  };
  return (
    <div className="scroll">
      <Col
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          minHeight: "100vh",
          border: "none",
          paddingInline: "40px",
        }}
        bordered={false}
      >
        <BocBang
          setCurrentSession={setCurrentSession}
          getSessionsToView={getSessionsToView}
          getContentToView={getContentToView}
          currentSession={currentSession}
          isListening={isListening}
          setListTranscript={setListTranscript}
          listTranscript={listTranscript}
        />
        <Typography className="text-sm font-bold ">
          {currentSession?.name}
        </Typography>
        <Col
          className="scroll"
          style={{
            width: "100%",
            margin: "20px auto 0px",
            backgroundColor: "#FFFFFF", // Grey background
            float: "left",
            height: "calc(-350px + 100vh)",
            // overflowY: "auto",
            border: "none",
            paddingInline: "35px",
          }}
        >
          {listTranscript && !!listTranscript?.length && !isContentLoading && (
            <List
              style={{
                paddingBottom: "200px",
              }}
              dataSource={[...listTranscript].reverse()}
              renderItem={(record) => (
                <List.Item style={{ padding: "12px 0" }}>
                  <List.Item.Meta
                    className="text-sm"
                    // avatar={<Avatar src={record?.representAvatar} />}
                    title={
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text strong>{record.representName}</Text>
                          <Text type="secondary">
                            <Popover
                              placement="rightTop"
                              content={
                                <div
                                  style={{
                                    width: "80px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setEditMess(record?.id);
                                    }}
                                  >
                                    Sửa
                                  </div>
                                  <div
                                    onClick={() => {
                                      setListTranscript((pre) =>
                                        pre?.filter(
                                          (item) => item?.id !== record?.id
                                        )
                                      );
                                    }}
                                  >
                                    Xóa
                                  </div>
                                </div>
                              }
                              trigger="click"
                            >
                              <MoreOutlined
                                style={{
                                  transform: "rotate(90deg)",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Popover>
                          </Text>
                        </div>
                      </>
                    }
                    description={
                      <>
                        {!!(record?.id === editMess) ? (
                          <>
                            <Form
                              form={form}
                              initialValues={{
                                [`InputEdit${record?.id}`]: record.passage,
                              }}
                            >
                              <Form.Item
                                noStyle
                                name={`InputEdit${record?.id}`}
                              >
                                <Input.TextArea
                                  placeholder="Nhập"
                                  style={{
                                    minHeight: "200px",
                                    overflow: "hidden auto",
                                  }}
                                />
                              </Form.Item>
                            </Form>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                marginTop: "8px",
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={async () => {
                                  const value = form.getFieldValue(
                                    `InputEdit${record?.id}`
                                  );
                                  await updateContent(record?.id, {
                                    passage: value,
                                  });
                                  getContentToView([
                                    ...currentSession?.content,
                                  ]);
                                  setEditMess(false);
                                }}
                              >
                                Lưu
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Text>
                            {record.passage &&
                              record.passage
                                .split(/(?<=[.?])/g) // Split at every "." or "?" and retain the delimiter
                                .map((segment, index) => (
                                  <span
                                    key={`note${index}`}
                                    style={{
                                      display: "block",
                                      marginTop: "4px",
                                    }}
                                  >
                                    {segment.trim()}
                                  </span>
                                ))}
                          </Text>
                        )}
                        <div style={{ textAlign: "right" }}>{record.time}</div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          )}
          {/* {isContentLoading && <Skeleton active paragraph={{ rows: 10 }} />} */}
        </Col>
      </Col>
    </div>
  );
};

export default MainTextDisplay;
