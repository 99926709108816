import React from 'react';

function ChildComponent1(props) {
    return (
        <div>
            fsfsffsfsf
        </div>
    );
}

export default ChildComponent1;