import dayjs from 'dayjs';
 
export function checkDateStatus(dateStr) {
    /**
     * Checks if a given date string in the format YYYY-MM-DD is yesterday, today, or neither.
     *
     * @param {string} dateStr - The date string to check.
     * @returns {string} "yesterday" if the date is yesterday,
     *                  "today" if the date is today,
     *                  "neither" otherwise.
     */
    try {
        // Parse the input date string
        const inputDate = dayjs(dateStr, "YYYY-MM-DD", true);

        // Validate date format
        if (!inputDate.isValid()) {
            throw new Error("Invalid date format. Please use YYYY-MM-DD.");
        }

        // Get today's date and yesterday's date
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');

        // Determine if the date is yesterday or today
        if (inputDate.isSame(today, 'day')) {
            return "Hôm nay";
        } else if (inputDate.isSame(yesterday, 'day')) {
            return "Hôm qua";
        } else {
            return "";
        }
    } catch (error) {
        throw new Error(error.message);
    }
}
