import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { Typography } from "antd";
import dayjs from "dayjs";
import _, { values } from "lodash";
import {
  createSession,
  patchUpdateSession,
  updateSession,
} from "./services/apiService";

// import user1 from "./assets/images/user1.png";
// import user2 from "./assets/images/user2.png";
// import user3 from "./assets/images/user3.png";
import { getSessionById } from "./services/apiService";
import { createContent } from "./services/apiService";
import { v4 as uuidv4 } from "uuid";
import { Select } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
// Declare the SpeechRecognition and related objects outside the component.
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechGrammarList =
  window.SpeechGrammarList || window.webkitSpeechGrammarList;
const SpeechRecognitionEvent =
  window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
if (!("webkitSpeechRecognition" in window))
  alert("Trình duyệt không hỗ trợ Web Speech API");
const { Paragraph } = Typography;
const mic = new SpeechRecognition();

function BocBang({
  setCurrentSession,
  getSessionsToView,
  isListening,
  setListTranscript,
  ListTranscript,
  currentSession,
  getContentToView,
}) {
  const { t, i18n } = useTranslation("global");
  mic.continuous = true;
  mic.interimResults = true;
  mic.lang = t("languageVoice");
  // console.log('language voice::::::::',mic.lang)
  // const { isRecording, setIsRecording } = useControlContext();
  const [note, setNote] = useState(null);
  const [speechend, setSpeechEnd] = useState(false);
  const [represent, setRepresent] = useState(1);

  const listRepresent = ["Người nói 1", "Người nói 2", "Người nói 3"];
  const user1 = "https://i.pravatar.cc/150?img=1";
  const user2 = "https://i.pravatar.cc/150?img=2";
  const user3 = "https://i.pravatar.cc/150?img=3";
  const listRepresentAvatar = [user1, user2, user3];
  useEffect(() => {
    handleListen();
  }, [isListening]);

  // useEffect(() => {
  //   if (speechend === true) {
  //     handleSaveNote();
  //   }
  // }, [speechend]);
  const handleListen = () => {
    if (isListening === true) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setNote(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
    mic.onspeechend = () => {
      handleSaveNote();
    };
    mic.onnomatch = async function (event) {
      // if (note?.split(" ").length > 1) {
      //   //save note to database
      //   const res = await createContent({
      //     id: uuidv4(),
      //     time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //     passage: note,
      //     represent: represent,
      //     representName: listRepresent[represent - 1],
      //     representAvatar: listRepresentAvatar[represent - 1],
      //     note,
      //   });
      //   if (res) {
      //     //phieên hiêện taại (session)
      //     const session = await getSessionById(currentSession?.id);
      //     // chuưa choọn section
      //     if (!!session?.id === false) {
      //       const resCreateSession = await createSession({
      //         id: uuidv4(),
      //         date: dayjs().format("YYYY-MM-DD"),
      //         name: note?.split("").splice(0, 15).join(""),
      //         content: [res?.id],
      //       });
      //       if (!!resCreateSession) {
      //         setCurrentSession(resCreateSession);
      //         getSessionsToView();
      //         getContentToView([res?.id]);
      //       } else {
      //         toast.error("Create session failed");
      //       }
      //     }
      //     !!session &&
      //       (await updateSession(session.id, {
      //         ...session,
      //         name: note?.split("").splice(0, 15).join(""),
      //         content: [...session.content, res?.id],
      //       }));
      //     await getSessionsToView();
      //     await getContentToView([...session.content, res?.id]);
      //     setNote("");
      //   }
      // } else {
      //   setNote("");
      //   toast.error("Note less than 1 word");
      // }
      // setListTranscript((pre) => [
      //   ...pre,
      //   {
      //     id: uuidv4(),
      //     time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //     name: listRepresent[represent - 1],
      //     passage: note,
      //     represent: represent,
      //     representName: listRepresent[represent - 1],
      //     representAvatar: listRepresentAvatar[represent - 1],
      //     note,
      //   },
      // ]);
      // setNote("I didn't recognise that color.");
      console.log("onnomatch");
    };

    mic.onerror = async function (event) {
      // if (note?.split(" ").length > 1) {
      //   //save note to database
      //   const res = await createContent({
      //     id: uuidv4(),
      //     time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //     passage: note,
      //     represent: represent,
      //     representName: listRepresent[represent - 1],
      //     representAvatar: listRepresentAvatar[represent - 1],
      //   });
      //   if (res) {
      //     //phieên hiêện taại (session)
      //     const session = await getSessionById(currentSession?.id);
      //     // chuưa choọn section
      //     if (!!session?.id === false) {
      //       const resCreateSession = await createSession({
      //         id: uuidv4(),
      //         date: dayjs().format("YYYY-MM-DD"),
      //         name: note?.split("").splice(0, 15).join(""),
      //         content: [res?.id],
      //       });
      //       if (!!resCreateSession?.id) {
      //         setCurrentSession(resCreateSession);
      //         getSessionsToView();
      //         getContentToView([res?.id]);
      //       } else {
      //         toast.error("Create session failed");
      //       }
      //     }
      //     !!session &&
      //       (await updateSession(session.id, {
      //         ...session,
      //         name: note?.split("").splice(0, 15).join(""),
      //         content: [...session.content, res?.id],
      //       }));
      //     await getSessionsToView();
      //     await getContentToView([...session.content, res?.id]);
      //     setNote("");
      //   }
      // } else {
      //   setNote("");
      //   console.log("note less than 1 word");
      //   toast.error("Note less than 1 word");
      // }
      mic.stop();
    };
  };

  const handleSaveNote = async () => {
    try {
      // console.log("note:::::", note);
      if (note.split(" ")?.length > 0) {
        //save note to database
        const res = await createContent({
          id: uuidv4(),
          time: dayjs().format("YYYY-MM-DD,HH:mm:ss"),
          passage: note,
          represent: represent,
          representName: listRepresent[represent - 1],
          representAvatar: listRepresentAvatar[represent - 1],
        });
        if (res) {
          //phieên hiêện taại (session)
          const session = await getSessionById(currentSession?.id);
          // chuưa choọn section
          if (!!session?.id === false) {
            const resCreateSession = await createSession({
              id: uuidv4(),
              date: dayjs().format("YYYY-MM-DD"),
              name: "Phiên họp mới",
              content: [res?.id],
            });
            if (!!resCreateSession) {
              setCurrentSession(resCreateSession);
              getSessionsToView();
              getContentToView([res?.id]);
            } else {
              toast.error("Create session failed");
            }
          }
          !!session &&
            (await updateSession(session.id, {
              ...session,
              content: [...session.content, res?.id],
            }));
          await getSessionsToView();
          await getContentToView([...session.content, res?.id]);
        }
      } else {
        console.log("note less than 1 word");
        toast.error("Note less than 1 word");
      }

      mic.stop();
      // mic.onend = () => {
      //   console.log("Stopped Mic on Click");
      // };
      // mic.start();
      // mic.onend = () => {
      //   console.log("continue..");
      //   mic.start();
      // };
    } catch (error) {
      console.log("errorhandleSaveNote", error);
    } finally {
      setNote("");
    }
  };

  return (
    <>
      {/* <h1 className="" style={{ marginBottom: "10px" }}>
        Chuyển đổi giọng nói
      </h1> */}
      <div className="container">
        <div className="box">
          {/* {isListening ? <span>🟢🎙️</span> : <span>🛑🎙️</span>} */}
          <span
            style={{
              fontWeight: "600",
              marginRight: "8px",
            }}
          >
            Chọn Người Nói :
          </span>
          <Select
            value={represent}
            onChange={(values) => {
              setRepresent(values);
            }}
            style={{
              marginRight: "8px",
            }}
          >
            {listRepresent?.map((item, idx) => (
              <Select.Option key={`select${idx + 1}`} value={idx + 1}>
                {item}
              </Select.Option>
            ))}
          </Select>
          {/* <h2>
            {!!isListening
              ? `${listRepresent[represent - 1]} đang phát biểu:`
              : "Mic đang tắt"}
          </h2> */}
          <div className="">
            {!!note &&
              note
                ?.split(/(?<=[.?])/g) // Split at every "." or "?" and retain the delimiter
                ?.map((segment, index) => (
                  <Paragraph key={`note${index}`} className="">
                    {segment.trim()}
                  </Paragraph>
                ))}
          </div>
        </div>
        {/* <div className="box">
          <h2>Lịch sử</h2>
          {savedNotes.map((n, idx) => (
            <p key={idx}>
              {n?.representName}: {n?.note}
            </p>
          ))}
        </div> */}
      </div>
    </>
  );
}

export default BocBang;
