
import { ConfigProvider } from "antd"
const WhiteTextTheme = ({ children }) => (
  <ConfigProvider
    theme={{
      token: {
        colorTextBase:'#fff',
      },
      components: {
        Menu: {
            itemSelectedColor: '#ffffff',
            itemSelectedBg: '#158AC4',
            itemBorderRadius: '5px',
        }
      },
    }}
  >
    {children}
  </ConfigProvider>
)
export default WhiteTextTheme