import {
  DownOutlined,
  FolderOutlined,
  MenuOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Flex,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  theme,
  Typography,
} from "antd";
import { Modal } from "antd";
import { FaBell, FaPencilAlt } from "react-icons/fa";
import { FaRegPenToSquare } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import "./App.css";
import BocBang from "./Bocbang.jsx";
import MicroGreen from "./assets/GreenMic.png";
import Logo from "./assets/LogoBocBang.png";
import MicroRed from "./assets/Micro-Red.png";
import MainTextDisplay from "./components/ConversationCard.jsx";
import { PiSidebarLight } from "react-icons/pi";
import { IoIosSearch } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import {
  createSession,
  getSessions,
  updateSession,
  updateSessionName,
} from "./services/apiService.js";
import { getContentById } from "./services/apiService.js";
import "animate.css";
import mic2 from "./assets/mic2.png";
import { FiBox } from "react-icons/fi";
import { RxDragHandleDots2 } from "react-icons/rx";
import { deleteSession } from "./services/apiService.js";
import { set } from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import WhiteTextTheme from "./theme/white.text.theme.jsx";
import { checkDateStatus } from "./functions/index.js";
import { Skeleton } from "antd";
import BocBangLogo from "../src/assets/BocBang2.png";
import { MdDeleteOutline } from "react-icons/md";
import { Form } from "antd";
const { Header, Content, Sider } = Layout;

// Render grouped items in the menu

const App = () => {
  const { t, i18n } = useTranslation("global");
  const languageOptions = [
    {
      label: (
        <span className="lang-icons">
          <span style={{ marginRight: "12px" }}>{t("language.vi")}</span>
          <ReactCountryFlag countryCode="VN" svg />
        </span>
      ),
      value: "vi",
    },
    {
      label: (
        <span className="lang-icons">
          {t("language.en")}
          <ReactCountryFlag countryCode="US" svg />
        </span>
      ),
      value: "en",
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [isListening, setIsListening] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // New state for search input visibility
  const [listTranscript, setListTranscript] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const recorder = useAudioRecorder();
  const [currentSession, setCurrentSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [openModalCreateSession, setOpenModalCreateSession] = useState(false);
  const [openModalSaveFile, setOpenModalSaveFile] = useState(false);
  const [nameSessionToCreate, setNameSessionToCreate] = useState("");
  const [nameSessionToEdit, setNameSessionToEdit] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [openModalUpdateSession, setOpenModalUpdateSession] = useState(false);
  const createMenuGroups = (groups) =>
    groups.map((group, index) => (
      <Menu.ItemGroup key={`groupmenu${index}`} title={group.title}>
        {group.items.map((item, idx) => (
          <Menu.Item
            style={{
              marginTop: "10px",
            }}
            key={item.id} // Use the session ID as the unique key
            onClick={() => {
              setCurrentSession(item);
              getContentToView(item?.content);
            }}
          >
            {!isSessionLoading ? (
              <div>
                <span>{item.name}</span>
                {currentSession?.id === item?.id && (
                  <div style={{ position: "absolute", right: 4, top: 0 }}>
                    <Dropdown
                      overlay={
                        <Menu
                          onClick={({ key }) => {
                            if (key === "2") {
                              showDeleteModal(item?.id);
                            }
                            if (key === "1") {
                              setOpenModalUpdateSession(true);
                            }
                          }}
                          items={[
                            {
                              label: (
                                <Space>
                                  <FaRegEdit />
                                  <span>{t("editSession")}</span>
                                </Space>
                              ),
                              // icon: <FaRegEdit />,
                              key: "1",
                              style: {
                                color: "black",
                              },
                              className: "custom-menu-item",
                            },
                            {
                              label: (
                                <Space>
                                  <MdDeleteOutline />
                                  <span>{t("deleteSession")}</span>
                                </Space>
                              ),
                              // icon: <MdDeleteOutline />,
                              key: "2",
                              style: {
                                color: "black",
                              },
                              className: "custom-menu-item",
                            },
                          ]}
                        />
                      }
                      trigger={["click"]}
                      placement="bottomRight"
                      arrow
                    >
                      <MoreOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          transform: "rotate(90deg)",
                        }}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
            ) : (
              <Skeleton active />
            )}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    ));
  const showDeleteModal = (sessionId) => {
    setSessionToDelete(sessionId);
    setDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    if (sessionToDelete) {
      await handleDeleteSession(sessionToDelete);
      setDeleteModalVisible(false);
      setSessionToDelete(null);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setSessionToDelete(null);
  };
  const getSessionsToView = async () => {
    try {
      setIsSessionLoading(true);
      const data = await getSessions();
      const groupedByDate = data.reduce((acc, session) => {
        const { date } = session;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(session);
        return acc;
      }, {});
      const groupedArray = Object.entries(groupedByDate).map(
        ([date, sessions]) => ({
          title: checkDateStatus(date) !== "" ? checkDateStatus(date) : date,
          items: sessions,
        })
      );
      setSessions(groupedArray.reverse());
      setIsSessionLoading(false);
    } catch (error) {
      setIsSessionLoading(false);
      toast.error("Get sessions failed");
      console.log(error);
    } finally {
      setIsSessionLoading(false);
    }
  };

  const handleCreateSession = async (name) => {
   try {
    if (!name) {
      toast.error("Vui lòng nhập tên phiên họp");
      setOpenModalCreateSession(false);
      return;
    }
    const res = await createSession({
      id: uuidv4(),
      date: dayjs().format("YYYY-MM-DD"),
      name: name,
      content: [],
    });
    if (!!res) {
      setCurrentSession(res);
      getSessionsToView();
      getContentToView([]);
      setNameSessionToCreate("");
      setOpenModalCreateSession(false);
    } else {
      toast.error("Create session failed");
    }
   } catch (error) {
     console.log(error);
     setOpenModalCreateSession(false);
   }
  };
  const handleEditSessionName = async (id, name) => {
    try {
      const nameToEdit = name;
      const res = await updateSessionName(id, nameToEdit);
      if (!!res) {
        toast.success("Update session name successfully");
        getSessionsToView();
      } else {
        toast.error("Update session name failed");
      }
      setOpenModalUpdateSession(false);
      setNameSessionToEdit("");
    } catch (error) {
      setOpenModalUpdateSession(false);
      console.log(error);
    }
  };
  const handleCancelCreateSession = () => {
    setOpenModalCreateSession(false);
  };
  const handleDeleteSession = async (id) => {
    const res = await deleteSession(id);
    if (!!res) {
      toast.warning("Delete session successfully");
      getSessionsToView();
    }
  };
  const getContentToView = async (ids) => {
    try {
      setIsContentLoading(true);
      if (!ids || !ids.length) {
        // toast.error("Phiên hiện tại chưa có bản ghi nào");
        setListTranscript([]);
        return;
      }
      // Fetch data for all IDs
      const data = await Promise.all(ids.map((id) => getContentById(id)));
      //count wword
      const wordCount = data.reduce((total, item) => {
        const words = item?.passage?.split(" ");
        return total + words?.length;
      }, 0);
      const characterCount = data.reduce((total, item) => {
        const characters = item?.passage || ""; // Ensure it's a string, even if `passage` is undefined
        return total + characters.length; // Add the length of the string to the total
      }, 0);
      console.log("check word count", wordCount);
      // count word
      setWordCount(Number(wordCount));
      setCharacterCount(Number(characterCount));
      // Update state with fetched data
      setListTranscript(data);
      setIsContentLoading(false);
    } catch (error) {
      toast.error("Error fetching content:", error);
      setListTranscript([]); // Reset to empty on error
    } finally {
      setIsContentLoading(false);
    }
  };
  const handleListening = () => {
    setIsListening(!isListening);
    isListening ? recorder.stopRecording() : recorder.startRecording();
  };

  const toggleSearch = () => {
    setIsSearchVisible((prev) => !prev); // Toggle visibility
  };
  useEffect(() => {
    if (collapsed) {
      setIsSearchVisible(false);
    }
  }, [collapsed]);
  useEffect(() => {
    getSessionsToView();
    // getContentToView([1]);
  }, []);
  useEffect(() => {
    if (currentSession) {
      getContentToView(currentSession?.content);
    }
  }, [currentSession]);
  return (
    <div className="">
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#27598C",
            maxHeight: "48px",
          }}
        >
          <div
            className="demo-logo ml-4"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              marginTop: "125px",
              zIndex: 1,
              alignItems: "center",
            }}
          >
            <img
              src={BocBangLogo}
              alt="Logo Boc Bang"
              style={{
                width: "50px",
                marginRight: "10px",
              }}
            />
            <div className="text-white text-3xl font-bold">
              {" "}
              {t("webname")}{" "}
            </div>
          </div>
        </Header>
        <Layout>
          <div className="sider">
            <WhiteTextTheme>
              <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                width={330}
                style={{
                  backgroundColor: "#27598C",
                  paddingInline: "10px",
                }}
              >
                <Menu
                  className="custom-menu"
                  mode="vertical "
                  style={{
                    marginTop: "120px",
                    backgroundColor: "#27598C",
                    overflow: "auto",
                    height: "calc(100vh - 120px)",
                    paddingBottom: "100px",
                  }}
                  selectedKeys={[currentSession?.id]}
                >
                  <Col></Col>
                  <Menu.Item
                    style={{
                      backgroundColor: "#158AC4",
                      width: "295px",
                    }}
                    onClick={() => {
                      setOpenModalCreateSession(true);
                    }}
                  >
                    <Flex align="center" justify="space-between">
                      <div>Tạo mới</div>
                      <FaRegPenToSquare fontSize={25} />
                    </Flex>
                  </Menu.Item>
                  {createMenuGroups(sessions)}
                </Menu>
              </Sider>
            </WhiteTextTheme>
          </div>

          <Layout
            style={{
              padding: "10px",
            }}
          >
            <Content
              style={{
                margin: 0,
                minHeight: 280,
                backgroundColor: "#FFFFFF",
                border: "1px solid #28598D",
              }}
            >
              <Layout.Header
                style={{
                  textAlign: "center",
                  padding: "10px 0",
                  minHeight: "100px",
                }}
              >
                <div
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  <hr
                    style={{
                      width: "100%",
                      position: "relative",
                      top: "45px",
                      height: "1.5px",
                      backgroundColor: "black",
                    }}
                  />
                </div>
                <Flex
                  className="mt-2 mr-3"
                  justify="flex-end"
                  gap="md"
                  align="center"
                >
                  <Space
                    style={{
                      marginRight: "30px",
                    }}
                    size={30}
                    wrap
                  >
                    <Input
                      size="large"
                      placeholder={t("search.placeholder")}
                      style={{ borderRadius: "5px" }}
                      suffix={<SearchOutlined style={{ color: "#28598D" }} />}
                    ></Input>
                    <Select
                      onChange={(value) => i18n.changeLanguage(String(value))}
                      size="large"
                      defaultValue="vi"
                      style={{ width: 150 }}
                      options={languageOptions}
                    />
                  </Space>
                  {!isListening ? (
                    <div
                      onClick={() => {
                        handleListening();
                      }}
                      className="micro-green"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        right: "10px",
                      }}
                    >
                      {/* <img src={MicroGreen} alt="Micro Green" /> */}
                      <div
                        className="mt-2"
                        style={{
                          padding: "10px",
                          background: "#F1EFEF",
                          borderRadius: "100%",
                          width: "68px",
                          height: "68px",
                          boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                        }}
                      >
                        <img
                          src={mic2}
                          alt="Mic2"
                          className="object-contain"
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        handleListening();
                      }}
                      style={{
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      className="circle-container mt-1"
                    >
                      <div className="circle">
                        <div className="rectangle "></div>
                        <div className="pulse-effect"></div>
                      </div>
                      <Flex
                        className=" relative right-6 min-w-52"
                        vertical={false}
                      >
                        <Space>
                          <div class="recording-dot"></div>
                          <Typography className="text-sm font-semibold">
                            Đang ghi âm
                          </Typography>
                        </Space>
                      </Flex>
                    </div>
                  )}
                </Flex>
              </Layout.Header>
              <div style={{ position: "relative" }}>
                <MainTextDisplay
                  wordCount={wordCount}
                  isContentLoading={isContentLoading}
                  setCurrentSession={setCurrentSession}
                  getSessionsToView={getSessionsToView}
                  getContentToView={getContentToView}
                  currentSession={currentSession}
                  isListening={isListening}
                  listTranscript={listTranscript}
                  setListTranscript={setListTranscript}
                />
              </div>
              <Affix className="max-h-9 " offsetBottom={0}>
                <Layout.Footer
                  className=" px-4"
                  style={{
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Col span={24}>
                    <Row>
                      <Flex className="relative bottom-5" align="flex-start">
                        <Flex>
                          <Space
                            style={{
                              color: "#635E5E",
                              fontFamily: "Inter",
                            }}
                            className="text-sm font-normal"
                            size={20}
                          >
                            <Typography>Số từ: {wordCount} </Typography>
                            <Typography>Số ký tự: {characterCount}</Typography>
                            <Typography>Số người tham gia: 4</Typography>
                          </Space>
                        </Flex>
                        <Flex className="ml-12 relative bottom-1">
                          <Space size={20}>
                            <Button type="default">Tóm tắt văn bản</Button>
                            <Button type="default">Sao chép văn bản</Button>
                            <Dropdown
                              className="max-w"
                              overlay={
                                <Menu
                                  onClick={({ key }) => {
                                    setOpenModalSaveFile(key);
                                  }}
                                  items={[
                                    { key: "1", label: "Lưu dưới dạng PDF" },
                                    { key: "2", label: "Lưu dưới dạng Word" },
                                    { key: "3", label: "Lưu dưới dạng TXT" },
                                  ]}
                                />
                              }
                              placement="topCenter"
                              trigger={["click"]}
                            >
                              <Button type="default">Lưu văn bản</Button>
                            </Dropdown>
                          </Space>
                        </Flex>
                      </Flex>
                    </Row>
                  </Col>
                </Layout.Footer>
              </Affix>
            </Content>
          </Layout>
        </Layout>
        {/* Modal for delete confirmation */}
        <Modal
          title="Xác nhận xóa phiên"
          visible={isDeleteModalVisible}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          okText="Yes, Delete"
          cancelText="Cancel"
        >
          <p className="text-gray-300">Bạn có chắc muốn xóa phiên?</p>
        </Modal>
        <Modal
          title="Tạo phiên họp"
          visible={openModalCreateSession}
          onOk={() => handleCreateSession(nameSessionToCreate)}
          onCancel={handleCancelCreateSession}
          okText={t("confirm")}
          cancelText={t("Reject")}
        >
          <Input
            placeholder="Điền tên phiên"
            value={nameSessionToCreate}
            onChange={(e) => setNameSessionToCreate(e.target.value)}
          />
        </Modal>
        <Modal
          bodyStyle={{ height: "269px" }}
          title="Lưu tài liệu này"
          visible={openModalSaveFile}
          // onOk={() => handleCreateSession(nameSessionToCreate)}
          onCancel={() => setOpenModalSaveFile(false)}
          okText={t("confirm")}
          cancelText={t("Reject")}
        >
          <Space className="w-full" direction="vertical">
            <Form Layout="vertical">
              <div className="flex gap-10 flex-col ">
                <Form.Item layout="vertical" label="Tên tài liệu">
                  <Input
                    size="large"
                    placeholder="File Name"
                    // value={nameSessionToCreate}
                    // onChange={(e) => setNameSessionToCreate(e.target.value)}
                  />
                </Form.Item>
                <Form.Item layout="vertical" label="Chọn địa chỉ lưu">
                  <Select
                    suffixIcon={<DownOutlined />}
                    size="large"
                    className="w-full"
                    prefix={<FolderOutlined />}
                  >
                    <Select.Option value="option1">Option 1</Select.Option>
                    <Select.Option value="option2">Option 2</Select.Option>
                    <Select.Option value="option2">Option 2</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </Space>
        </Modal>
        <Modal
          title="Đổi tên phiên họp"
          visible={openModalUpdateSession}
          onOk={() =>
            handleEditSessionName(currentSession?.id, nameSessionToEdit)
          }
          onCancel={() => setOpenModalUpdateSession(false)}
          okText={t("confirm")}
          cancelText={t("Reject")}
        >
          <Input
            placeholder="Enter session name to update"
            value={nameSessionToEdit}
            onChange={(e) => setNameSessionToEdit(e.target.value)}
          />
        </Modal>
      </Layout>
    </div>
  );
};

export default App;
